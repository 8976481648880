<template>
    <form class="search-inputs" @submit.prevent="startSearch">
        <div class="towns-search text">
            <input
                    class="search-type"
                    type="text"
                    placeholder="Откуда"
                    v-model="data.from.text"
                    @click="openComplete('from'); $event.target.blur()">

        </div>

        <div class="towns-search text">
            <input
                    class="search-type"
                    type="text"
                    placeholder="Куда"
                    v-model="data.to.text"
                    @click="openComplete('to'); $event.target.blur()">
        </div>

        <div class="date-pickers">
            <div class="calendar">
                <date-picker v-model="data.date" placeholder="Дата вылета"
                             :format="'DD-MM-YYYY'"
                             :value-type="'YYYY-MM-DD'" :disabled-date="$core.disabledDate"
                             :clearable="false"/>
            </div>
        </div>

        <div class="date-pickers">
            <div class="calendar">
                <date-picker v-model="data.returnDate" placeholder="Обратно"
                             :format="'DD-MM-YYYY'"
                             :value-type="'YYYY-MM-DD'" :disabled-date="$core.disabledDate"
                             :clearable="false"/>
            </div>
        </div>

        <div class="form-group">
            <label @click="$store.state.selectPlacement = !$store.state.selectPlacement">Пассажиры</label>
            <input type="text" class="search-room" v-model="passenger" :readonly="true"
                   @click="$store.state.selectPlacement = !$store.state.selectPlacement">
            <transition name="fade">
                <div class="select-placement" v-if="$store.state.selectPlacement">
                    <div class="placement-item">
                        <div class="placement-item-titles">
                            <div class="placement-item-title">Взрослые</div>
                            <div class="placement-item-sub-title">старше 17 лет</div>
                        </div>
                        <div class="placement-item-counter">
                            <button type="button" class="btn" @click="updateCounter('adults', 0)"
                                    :disabled="data.adults < 2">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" class="a2ka">
                                    <path fill="currentColor"
                                          d="M3 7a1 1 0 0 0 0 2h10a1 1 0 1 0 0-2H3Z"></path>
                                </svg>
                            </button>
                            <input class="counter" v-model="data.adults" :disabled="true"/>
                            <button type="button" class="btn" @click="updateCounter('adults', 1)">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" class="a2ka">
                                    <path fill="currentColor"
                                          d="M9 3a1 1 0 0 0-2 0v4H3a1 1 0 0 0 0 2h4v4a1 1 0 1 0 2 0V9h4a1 1 0 1 0 0-2H9V3Z"></path>
                                </svg>
                            </button>
                        </div>
                    </div>
                    <div class="placement-item">
                        <div class="placement-item-titles">
                            <div class="placement-item-title">Дети</div>
                            <div class="placement-item-sub-title">от 0 до 17 лет</div>
                        </div>
                        <div class="placement-item-counter">
                            <button type="button" class="btn" @click="updateCounter('children', 0)"
                                    :disabled="data.children < 1">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" class="a2ka">
                                    <path fill="currentColor"
                                          d="M3 7a1 1 0 0 0 0 2h10a1 1 0 1 0 0-2H3Z"></path>
                                </svg>
                            </button>
                            <input class="counter" v-model="data.children" :disabled="true"/>
                            <button type="button" class="btn" @click="updateCounter('children', 1)">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" class="a2ka">
                                    <path fill="currentColor"
                                          d="M9 3a1 1 0 0 0-2 0v4H3a1 1 0 0 0 0 2h4v4a1 1 0 1 0 2 0V9h4a1 1 0 1 0 0-2H9V3Z"></path>
                                </svg>
                            </button>
                        </div>
                    </div>
                    <div class="placement-item child-age" v-if="data.children > 0">
                        <multiselect
                                v-for="index in data.children"
                                :key="index"
                                v-model="childrenAges[index - 1]"
                                track-by="name"
                                label="name"
                                :options="options"
                                :searchable="false"
                                :close-on-select="true"
                                :show-labels="false"
                                placeholder="Укажите возраст"
                                :openDirection="'below'"
                        ></multiselect>
                    </div>
                </div>
            </transition>
        </div>

        <button type="submit" class="btn btn-primary search-hotel">Найти билеты</button>

        <auto-compete-mobile-avia
                v-if="$store.state.width <= 992"
                v-show="$store.state.avia.autoCompleteMobile" :type="this.type"/>
    </form>
</template>

<script>

import AutoCompeteMobileAvia from "@/components/AutoCompete/avia/AutoCompeteMobile.vue";

export default {
    name: 'index-avia-search',
    components: {AutoCompeteMobileAvia},
    data: function () {
        return {
            data: {
                from: {
                    text: '',
                    code: '',
                },
                to: {
                    text: '',
                    code: '',
                },
                date: this.$moment().add(1, 'days').format('Y-MM-DD'),
                returnDate: null,
                adults: 1,
                children: 0,
                ages: [],
            },
            autocomplete: {
                cities: []
            },
            type: 'from',
            fromLoading: false,
            toLoading: false,
            timer: null,
            childrenAges: [],
            options: [
                {name: 'До года', code: '0'},
                {name: '1 год', code: '1'},
                {name: '2 года', code: '2'},
                {name: '3 года', code: '3'},
                {name: '4 года', code: '4'},
                {name: '5 лет', code: '5'},
                {name: '6 лет', code: '6'},
                {name: '7 лет', code: '7'},
                {name: '8 лет', code: '8'},
                {name: '9 лет', code: '9'},
                {name: '10 лет', code: '10'},
                {name: '11 лет', code: '11'},
                {name: '12 лет', code: '12'},
                {name: '13 лет', code: '13'},
                {name: '14 лет', code: '14'},
                {name: '15 лет', code: '15'},
                {name: '16 лет', code: '16'},
                {name: '17 лет', code: '17'}
            ],
        }
    },
    computed: {
        passenger: function () {
            let count = this.data.adults + this.data.children;
            return count + ' ' + this.$core.declination(count, ['пассажир', 'пассажира', 'пассажиров']);
        },
    },
    async mounted() {
        this.$root.$on('autocomplete', (val) => {
            this.setAutoComplete(val)
        });
    },
    methods: {
        openComplete: function (type) {
            this.type = type
            this.$store.state.avia.autoCompleteMobile = true
        },
        setAutoComplete: function (val) {
            this.data[val.type].text = val.name + ' (' + val.code + ')'
            this.data[val.type].code = val.code
        },
        updateCounter: function (variable, type) {
            if (type === 0 && variable === 'adults' && this.data[variable] > 1) {
                this.data[variable]--;
            } else if (type === 0 && variable === 'children' && this.data[variable] >= 1) {
                this.data[variable]--;
                this.childrenAges.pop();
            } else if (variable === 'children' && this.data[variable] < 4) {
                this.data[variable]++;
            } else if (variable !== 'children' && this.data[variable] < 6) {
                this.data[variable]++;
            }
        },
        startSearch: function () {
            if (this.data.from.code.length <= 0 || this.data.to.code.length <= 0) {
                this.$toast.error('Выберите откуда и куда полетите')
                return false
            }

            this.$router.push({
                name: 'avia-catalog',
                query: {
                    from: this.data.from.code,
                    to: this.data.to.code,
                    date: this.data.date,
                    returnDate: this.data.returnDate,
                    adults: this.data.adults,
                    children: this.data.ages,
                }
            })
        },
    }
}
</script>