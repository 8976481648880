<template>
    <div class="main">

        <!-- Декстоп слайдер -->

        <div class="container">
            <div class="slider">
                <template v-if="$store.state.width > 992">
                    <VueSlickCarousel class="main-carousel" v-bind="settings" v-if="getSlides.length > 0">
                        <router-link v-for="slide in getSlides" :key="slide.id" to="/">
                            <img :src="slide.image_web">
                        </router-link>
                    </VueSlickCarousel>
                </template>
            </div>
        </div>

        <!-- Декстоп слайдер END -->

        <!-- Мобильный слайдер -->

        <template v-if="$store.state.width <= 992">
            <VueSlickCarousel class="main-carousel" v-bind="settings" v-if="getSlides.length > 0">
                <router-link v-for="slide in getSlides" :key="slide.id" to="/">
                    <img :src="slide.image_mobile">
                </router-link>
            </VueSlickCarousel>
        </template>

        <!-- Мобильный слайдер END -->

        <!-- Мобильный поиск -->

        <div class="main-search" v-if="$store.state.width <= 992">
            <div class="container">
                <div class="switch-buttons">
                    <a class="switch-button" :class="[types.hotel ? 'active': '']"
                       @click="selectType('hotel')">Отели</a>
                    <a class="switch-button" :class="[types.avia ? 'active': '']" @click="selectType('avia')">Авиа</a>
                </div>

                <form class="search-inputs" @submit.prevent="startSearch" v-if="types.hotel">
                    <div class="towns-search">
                        <input
                                class="search-type"
                                type="text"
                                placeholder="Город или отель"
                                v-model="data.text"
                                @click="$store.state.autoCompleteMobile = true; $event.target.blur()"/>
                    </div>
                    <div class="date-pickers">
                        <div class="calendar">
                            <date-picker2 range v-model="data.date" placeholder="Даты бронирования"
                                          :format="'DD-MM-YYYY'"
                                          :value-type="'YYYY-MM-DD'" :disabled-date="$core.disabledDate"
                                          :clearable="false"/>
                        </div>
                    </div>
                    <div class="form-group">
                        <label @click="$store.state.selectPlacement = !$store.state.selectPlacement">Размещение</label>
                        <input type="text" class="search-room" v-model="selectedRoom" :readonly="true"
                               @click="$store.state.selectPlacement = !$store.state.selectPlacement">
                        <transition name="fade">
                            <div class="select-placement" v-if="$store.state.selectPlacement">
                                <div class="placement-item">
                                    <div class="placement-item-titles">
                                        <div class="placement-item-title">Взрослые</div>
                                        <div class="placement-item-sub-title">старше 17 лет</div>
                                    </div>
                                    <div class="placement-item-counter">
                                        <button type="button" class="btn" @click="updateCounter('adults', 0)"
                                                :disabled="data.adults < 2">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" class="a2ka">
                                                <path fill="currentColor"
                                                      d="M3 7a1 1 0 0 0 0 2h10a1 1 0 1 0 0-2H3Z"></path>
                                            </svg>
                                        </button>
                                        <input class="counter" v-model="data.adults" :disabled="true"/>
                                        <button type="button" class="btn" @click="updateCounter('adults', 1)">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" class="a2ka">
                                                <path fill="currentColor"
                                                      d="M9 3a1 1 0 0 0-2 0v4H3a1 1 0 0 0 0 2h4v4a1 1 0 1 0 2 0V9h4a1 1 0 1 0 0-2H9V3Z"></path>
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                                <div class="placement-item">
                                    <div class="placement-item-titles">
                                        <div class="placement-item-title">Дети</div>
                                        <div class="placement-item-sub-title">от 0 до 17 лет</div>
                                    </div>
                                    <div class="placement-item-counter">
                                        <button type="button" class="btn" @click="updateCounter('children', 0)"
                                                :disabled="data.children < 1">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" class="a2ka">
                                                <path fill="currentColor"
                                                      d="M3 7a1 1 0 0 0 0 2h10a1 1 0 1 0 0-2H3Z"></path>
                                            </svg>
                                        </button>
                                        <input class="counter" v-model="data.children" :disabled="true"/>
                                        <button type="button" class="btn" @click="updateCounter('children', 1)">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" class="a2ka">
                                                <path fill="currentColor"
                                                      d="M9 3a1 1 0 0 0-2 0v4H3a1 1 0 0 0 0 2h4v4a1 1 0 1 0 2 0V9h4a1 1 0 1 0 0-2H9V3Z"></path>
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                                <div class="placement-item child-age" v-if="data.children > 0">
                                    <multiselect
                                            v-for="index in data.children"
                                            :key="index"
                                            v-model="childrenAges[index - 1]"
                                            track-by="name"
                                            label="name"
                                            :options="options"
                                            :searchable="false"
                                            :close-on-select="true"
                                            :show-labels="false"
                                            placeholder="Укажите возраст"
                                            :openDirection="'below'"
                                    ></multiselect>
                                </div>
                            </div>
                        </transition>
                    </div>
                    <button type="submit" class="btn btn-primary search-hotel">Найти отель</button>
                </form>

                <index-avia-search v-else-if="types.avia"/>

            </div>
        </div>

        <!-- Мобильный поиск END -->

        <div class="container">

            <!-- Рекомендуем блок -->

            <div class="best-offers" v-if="getRecommended.length > 0">
                <h1 class="container-title">Рекомендуем</h1>
                <div class="best-offers-items">
                    <div class="best-offers-item" v-for="(item, index) in getRecommended" :key="index">
                        <div class="image">
                            <img
                                    :src="item.images[0].url.replace('{size}', $store.getters.width <= 740 ? '1024x768' : '240x240')"
                                    :alt="item.name"
                                    v-if="item.images.length > 0">
                            <img src="@/assets/images/offers/offer.png" v-else>
                            <div class="rate">
                                <span>Отель</span>
                                <div class="stars">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" class="b7da"
                                         v-for="index in item.star_rating"
                                         :key="index">
                                        <path fill="currentColor"
                                              d="M8 2a1 1 0 0 1 .87.508l1.538 2.723 2.782.537a1 1 0 0 1 .538 1.667L11.711 9.58l.512 3.266A1 1 0 0 1 10.8 13.9L8 12.548 5.2 13.9a1 1 0 0 1-1.423-1.055l.512-3.266-2.017-2.144a1 1 0 0 1 .538-1.667l2.782-.537 1.537-2.723A1 1 0 0 1 8 2Z"></path>
                                    </svg>
                                </div>
                            </div>
                        </div>

                        <div class="price">
                            {{ parseFloat(item.price).toLocaleString('RU-ru') }} ₽
                        </div>

                        <div class="name">
                            {{ item.name }}
                        </div>

                        <div class="region">
                            {{ item.location }}
                        </div>

                        <a class="btn btn-primary" @click="openHotel(item)">Забронировать</a>
                        <span class="add-info">За одну ночь</span>
                    </div>
                </div>
            </div>

            <!-- Рекомендуем блок END -->

            <!-- Курорты России блок -->

            <div class="resorts" v-if="getBanners.length > 0">
                <h1 class="container-title">Курорты России</h1>
                <template v-if="$store.state.width <= 992">
                    <VueSlickCarousel class="row-slider" v-bind="settings2">
                        <div v-for="slide in getBanners[1]" :key="slide.id">
                            <router-link :to="slide.url_mobile">
                                <img :src="slide.image_mobile" v-if="slide.place_id === 1">
                            </router-link>
                        </div>
                    </VueSlickCarousel>
                </template>

                <template v-else>
                    <VueSlickCarousel class="row-slider" v-bind="settings2">
                        <div v-for="slide in getBanners[1]" :key="slide.id">
                            <router-link :to="slide.url_web">
                                <img :src="slide.image_web" v-if="slide.place_id === 1">
                            </router-link>
                        </div>
                    </VueSlickCarousel>
                </template>
            </div>

            <!-- Курорты России блок END -->

            <!-- Большой баннер -->

            <div class="banner-big">
                <div class="banner pointer" v-if="getBanners.length > 0">
                    <template v-if="$store.state.width <= 992">
                        <div v-for="slide in getBanners[2]" :key="slide.id">
                            <router-link :to="slide.url_mobile">
                                <img :src="slide.image_mobile" v-if="slide.place_id === 2">
                            </router-link>
                        </div>
                    </template>
                    <template v-if="$store.state.width > 992">
                        <div v-for="slide in getBanners[2]" :key="slide.id">
                            <router-link :to="slide.url_web">
                                <img :src="slide.image_web" v-if="slide.place_id === 2">
                            </router-link>
                        </div>
                    </template>
                </div>
            </div>

            <!-- Большой баннер END -->

            <!-- Маленькие баннеры -->

            <div class="banner-small" v-if="getBanners.length > 0">
                <template v-if="$store.state.width <= 992">
                    <div class="banner pointer" v-for="slide in getBanners[3]" :key="slide.id">
                        <router-link :to="slide.url_mobile">
                            <img :src="slide.image_mobile" v-if="slide.place_id === 3">
                        </router-link>
                    </div>
                </template>
                <template v-if="$store.state.width > 992">
                    <div class="banner pointer" v-for="slide in getBanners[3]" :key="slide.id">
                        <router-link :to="slide.url_web">
                            <img :src="slide.image_web" v-if="slide.place_id === 3">
                        </router-link>
                    </div>
                </template>
            </div>

            <!-- Маленькие баннеры END -->

        </div>

        <auto-compete-mobile
                v-if="$store.state.width <= 992"
                v-show="$store.state.autoCompleteMobile"/>

    </div>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel';
import DatePicker2 from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/ru';
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import AutoCompeteMobile from "@/components/AutoCompete/hotel/AutoCompleteMobile.vue";
import IndexAviaSearch from "@/components/mobile/index/search/avia.vue";
import {mapGetters} from "vuex";

export default {
    name: 'index',
    components: {IndexAviaSearch, AutoCompeteMobile, VueSlickCarousel, DatePicker2},
    data: function () {
        return {
            data: {
                text: '',
                type: 0,
                id: 0,
                date: [
                    this.$moment().add(7, 'days').format('Y-MM-DD'),
                    this.$moment().add(9, 'days').format('Y-MM-DD')
                ],
                adults: 1,
                children: 0,
                ages: [],
            },
            types: {
                hotel: true,
                avia: false,
            },
            autocomplete: {
                hotels: [],
                regions: [],
            },
            settings: {
                dots: true,
                arrows: true,
                edgeFriction: 0.35,
                fade: false,
                infinite: true,
                autoplay: true,
                autoplaySpeed: 4000,
                speed: 500,
                slidesToShow: 1,
                slidesToScroll: 1,
                dotsClass: "slick-dots custom-dot-class",
                adaptiveHeight: true,
            },
            settings2: {
                dots: true,
                arrows: true,
                infinite: true,
                slidesToShow: 3,
                autoplay: false,
                autoplaySpeed: 4000,
                speed: 500,
                rows: 2,
                slidesPerRow: 1,
                slidesToScroll: 3,
                dotsClass: "slick-dots custom-dot-class3",
                responsive: [
                    {
                        breakpoint: 1200,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2,
                        }
                    },
                    {
                        breakpoint: 992,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                        }
                    },
                ]
            },
            childrenAges: [],
            options: [
                {name: 'До года', code: '0'},
                {name: '1 год', code: '1'},
                {name: '2 года', code: '2'},
                {name: '3 года', code: '3'},
                {name: '4 года', code: '4'},
                {name: '5 лет', code: '5'},
                {name: '6 лет', code: '6'},
                {name: '7 лет', code: '7'},
                {name: '8 лет', code: '8'},
                {name: '9 лет', code: '9'},
                {name: '10 лет', code: '10'},
                {name: '11 лет', code: '11'},
                {name: '12 лет', code: '12'},
                {name: '13 лет', code: '13'},
                {name: '14 лет', code: '14'},
                {name: '15 лет', code: '15'},
                {name: '16 лет', code: '16'},
                {name: '17 лет', code: '17'}
            ]
        }
    },
    watch: {
        childrenAges(newValue) {
            this.data.ages = []
            Object.keys(newValue).forEach((index) => {
                this.data.ages.push(this.childrenAges[index].code)
            })
        }
    },
    computed: {
        ...mapGetters(['getSlides', 'getBanners', 'getRecommended']),
        selectedRoom: function () {
            return (this.data.adults + this.data.children) + ' гость';
        },
    },
    async mounted() {
        this.getIndexInfo();
        this.$root.$on('autocomplete', (val) => {
            this.setAutoComplete(val)
        });
    },
    methods: {
        selectType: function (val) {
            let $this = this;
            this.types[val] = true;
            Object.keys(this.types).forEach(function (key) {
                if (key !== val) {
                    $this.types[key] = false;
                }
            });
            this.data.text = ''
            this.data.type = 0
            this.data.id = 0
            this.data.date[0] = this.$moment().add(7, 'days').format('Y-MM-DD')
            this.data.date[1] = this.$moment().add(9, 'days').format('Y-MM-DD')
            this.data.adults = 1
            this.data.children = 0
            this.data.ages = []
        },
        startSearch: function () {
            if (this.types.hotel) {
                if (this.data.id <= 0) {
                    this.$toast.error('Выберите город или отель')
                    return false
                }

                if (this.data.type === 1) {
                    this.$router.push({
                        name: 'hotels',
                        query: {
                            id: this.data.id,
                            text: this.data.text,
                            type: this.data.type,
                            dateArrival: this.data.date[0],
                            dateDeparture: this.data.date[1],
                            adults: this.data.adults,
                            children: this.data.ages,
                        }
                    })
                } else {
                    this.$router.push({
                        name: 'hotel',
                        params: {id: this.data.id},
                        query: {
                            id: this.data.id,
                            text: this.data.text,
                            type: this.data.type,
                            dateArrival: this.data.date[0],
                            dateDeparture: this.data.date[1],
                            adults: this.data.adults,
                            children: this.data.ages
                        }
                    })
                }
            }
        },
        updateCounter: function (variable, type) {
            if (type === 0 && variable === 'adults' && this.data[variable] > 1) {
                this.data[variable]--;
            } else if (type === 0 && variable === 'children' && this.data[variable] >= 1) {
                this.data[variable]--;
                this.childrenAges.pop();
            } else if (variable === 'children' && this.data[variable] < 4) {
                this.data[variable]++;
            } else if (variable !== 'children' && this.data[variable] < 6) {
                this.data[variable]++;
            }
        },
        setAutoComplete: function (val) {
            this.data.id = val.id
            this.data.text = val.name
            this.data.type = val.type
        },
        openHotel: function (item) {
            this.$router.push({
                name: 'hotel',
                params: {id: item.id},
                query: {
                    id: item.id,
                    dateArrival: this.data.date[0],
                    dateDeparture: this.data.date[1],
                    adults: this.data.adults,
                    children: this.data.ages
                }
            })
        },
        // запросы на сервер
        getIndexInfo: function () {
            if (this.getSlides.length === 0 || this.getBanners.length === 0 || this.getRecommended.length === 0) {
                this.$store.dispatch('getIndexInfo')
            }
        },
    }
}
</script>
